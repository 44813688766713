import { AuthProvider } from "AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useGoogleAnalytics } from "useGoogleAnalytics";
import Home from "pages/home";
import theme from "theme/theme.js";
import Drinks from "pages/admin/drink";
import ShelfPage from "pages/shelfPage";
import ErrorPage from "pages/errorPage";
import { createRoot } from "react-dom/client";
import CocktailPage from "pages/cocktailPage";
import SettingsPage from "pages/settingsPage";
import { ChakraProvider } from "@chakra-ui/react";
import IngredientPage from "pages/ingredientPage";

const container = document.getElementById("root");
const root = createRoot(container);

const App = () => {
  useGoogleAnalytics("G-JWCSZ4WFPN");

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/ingredient/:ingredientName" element={<IngredientPage />} />
            <Route path="/cocktail/:cocktailName" element={<CocktailPage />} />
            <Route path="/admin" element={<Drinks />} />
            <Route path="/shelf" element={<ShelfPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="*" element={<ErrorPage statusCode={404} />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ChakraProvider>
  );
};

root.render(<App />);