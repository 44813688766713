import React from 'react';
import { ReactNode, useRef } from 'react';

import { useForm, UseFormRegisterReturn } from 'react-hook-form';

import { ArrowUpIcon } from '@chakra-ui/icons';
import { Button, FormControl, FormErrorMessage, FormLabel, InputGroup } from '@chakra-ui/react';

import { useAuth } from '../AuthContext.jsx';

type FileUploadProps = {
  register: UseFormRegisterReturn
  accept?: string
  multiple?: boolean
  children?: ReactNode
}

const FileUpload = (props: FileUploadProps) => {
  const { register, accept, multiple, children } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register as {ref: (instance: HTMLInputElement | null) => void}

  const handleClick = () => inputRef.current?.click()

  return (
      <InputGroup onClick={handleClick}>
        <input
          type={'file'}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e)
            inputRef.current = e
          }}
        />
        <>
          {children}
        </>
      </InputGroup>
  )
}

type FormValues = {
  file_: FileList
}

const FileUploadForm = () => {
  const { fetchToken } = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>()
  const onSubmit = handleSubmit((data) => {
    console.log('On Submit: ', data.file_)

    var to_upload = new FormData()
    to_upload.append('file', data.file_[0])
    fetch("/api/drink/import", {
      method: "POST",
      headers: { Authorization: `Bearer ${fetchToken()}` },
      body: to_upload
    })
  })

  const validateFiles = (value: FileList) => {
    if (value.length < 1) {
      return 'Files is required'
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024)
      const MAX_FILE_SIZE = 10
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 10mb'
      }
    }
    return true
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormControl isInvalid={!!errors.file_} isRequired>
          <FormLabel>{'File input'}</FormLabel>

          <FileUpload
            accept=".csv,.json,.txt"
            register={register('file_', { validate: validateFiles })}
          >
            <Button leftIcon={<ArrowUpIcon />}>
              Upload
            </Button>
          </FileUpload>

          <FormErrorMessage>
            {errors.file_ && errors?.file_.message}
          </FormErrorMessage>
        </FormControl>

        <button>Submit</button>
      </form>
    </>
  )
}

export default FileUploadForm