import { useEffect, useState } from "react";

import { useAuth } from "AuthContext";

import CardOfDrink from "components/CardOfDrink";
import useUserService from "services/userService";
import useDrinkService from "services/drinkService";
import useCommonService from "services/commonService";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Grid, Stack, Text, Tooltip } from '@chakra-ui/react';

import BasePage from "./basePage";

export default function Home() {
  const [drinks, setDrinks] = useState([]);

  const [filteredDrinks, setFilteredDrinks] = useState(null);
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  const { getFilters } = useCommonService();
  const { getFeaturedDrinks, getDrinksByFilters } = useDrinkService();
  const { getFavorites } = useUserService();

  const [favorites, setFavorites] = useState([]);

  const auth = useAuth();

  useEffect(() => {
    const fetchDrinks = async () => {
      setDrinks(await getFeaturedDrinks());
    };
  
    const fetchFilters = async () => {
      const response = await getFilters()
      setFilters(response);
      const possibleFilters = {};

      Object.keys(filters).map((key) => (
        possibleFilters[key] = []
      ));

      setSelectedFilters(possibleFilters);
    };

    const fetchFavorites = async () => {
      if (auth.isLoggedIn) {
        const favorites = await getFavorites();
        const ids = favorites.map((favorite) => favorite.drink_id);
        setFavorites(ids);
      }
    }

    fetchFavorites();
    fetchDrinks();
    fetchFilters();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    const fetchFilteredDrinks = async () => {
      setFilteredDrinks(await getDrinksByFilters(selectedFilters));
    };

    if (Object.keys(selectedFilters).length > 0) {
      fetchFilteredDrinks();
    }

  }, [selectedFilters, auth.isLoggedIn]);

  const handleFilterChanged = (key, value) => (e) => {
    setSelectedFilters((prevValues) => {
      const currentValues = prevValues[key] || [];
      if (e.target.checked) {
        return {
          ...prevValues,
          [key]: [...currentValues, value],
        };
      } else {
        return {
          ...prevValues,
          [key]: currentValues.filter((v) => v !== value),
        };
      }
    });
  };

  const hasSelectedValues = Object.values(selectedFilters).some((arr) => arr.length > 0);
  const numOfSelectedValues = Object.values(selectedFilters).flat().length;
  const limitOfSelections = auth.isLoggedIn ? 200 : 3;

  const tooltipLimits = "Register to use filters unlimitedly";

  return (
    <BasePage
      sidebar={
        <>
          <Text fontSize="xl" mb={4} mt={4}>Filters {!auth.isLoggedIn && <Tooltip label={tooltipLimits}><Text as="i" borderBottom={"1px dotted"}>(up to 3)</Text></Tooltip>}</Text>
            <Accordion allowMultiple defaultIndex={[0]}>
              {Object.keys(filters).map((key) => (
                <AccordionItem key={key}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {key}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack spacing={2}>
                      {filters[key].map((value, index) => (
                        <Checkbox
                          name={value}
                          key={index}
                          value={value}
                          isChecked={selectedFilters[key]?.includes(value)}
                          onChange={handleFilterChanged(key, value)}
                          isDisabled={!selectedFilters[key]?.includes(value) && numOfSelectedValues >= limitOfSelections}
                        >
                          {value}
                        </Checkbox>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
        </>
      }
      mainContent={
        hasSelectedValues ? (
          filteredDrinks != null && filteredDrinks.length > 0 ? (
            <>
              <Text fontSize="lg" mb={5}>Filtered cocktails</Text>
              <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
                {filteredDrinks.map((cocktail) => (
                  <CardOfDrink key={cocktail.id} drink={cocktail} favorite={favorites.includes(cocktail.id)} />
                ))}
              </Grid>
            </>
          ) : (<Text fontSize="lg" mb={5}>Filtered cocktails - Not found</Text>)
        ) : (
          <>
            <Text fontSize="lg" mb={5}>Featured cocktailsss</Text>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
              {drinks.map((cocktail) => (
                <CardOfDrink key={cocktail.id} drink={cocktail} favorite={favorites.includes(cocktail.id)} />
              ))}
            </Grid>
          </>
        )
      }
    />
  )
}
