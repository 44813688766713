import { useAuth } from "AuthContext";

import { MdOutlineLogout, MdOutlineSettings } from "react-icons/md";
import { Avatar, Button, Center, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';

export default function UserDroplist() {
  const { logout, userData } = useAuth();

  return(
    <Menu>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        minW={0}>
        <Avatar
          size={'sm'}
          src={'https://api.dicebear.com/9.x/adventurer-neutral/svg?seed=Male'}
        />
      </MenuButton>
      <MenuList alignItems={'center'}>
        <br />
        <Center>
          <Avatar
            size={'2xl'}
            src={'https://api.dicebear.com/9.x/adventurer-neutral/svg?seed=Male'}
          />
        </Center>
        <br />
        <Center>
          <p>{userData['name']}</p>
        </Center>
        <br />
        <MenuDivider />
        <MenuItem as='a' href='settings' icon={<MdOutlineSettings />}>Settings</MenuItem>
        <MenuItem onClick={() => {logout()}} icon={<MdOutlineLogout  />}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}
