import React, { createContext, useContext, useEffect, useState } from 'react';

import useUserService from 'services/userService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('drinkToken') || '')
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false);
  const [userData, setUserData] = useState({});

  const { getMyUserData } = useUserService();

  useEffect(() => {
    if(isLoggedIn) {
      const storedToken = localStorage.getItem('drinkToken');
      setToken(storedToken);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if(token) {
      getMyUserData(token).then((data) => {
        setUserData(data);
      });
    }
  }, [token]);

  const login = (token) => {
    localStorage.setItem('drinkToken', token);
    setToken(token);
    setIsLoggedIn(true);
  }

  const logout = () => {
    localStorage.removeItem('drinkToken');
    setToken('');
    setIsLoggedIn(false);
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, token, userData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
