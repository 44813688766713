import { useState } from "react";

import { useAuth } from "AuthContext";

import { FaHeart } from "react-icons/fa";
import { MdMenuBook } from "react-icons/md";
import useUserService from "services/userService";
import { FaHeartCirclePlus, FaHeartCircleXmark } from "react-icons/fa6";
import { Badge, Box, Center, Flex, Grid, GridItem, Image, Link, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

export default function CardOfDrink({ drink, favorite }) {
  const cocktailColor = useColorModeValue("gray.400", "gray.900");
  const [isFavorite, setIsFavorite] = useState(favorite);

  const { deleteFavorite, addFavorite } = useUserService();

  const { isLoggedIn, userData } = useAuth();

  const toggleFavorite = async () => {
    if (isFavorite) {
      await deleteFavorite(drink.id);
    } else {
      await addFavorite(drink.id);
    }
    setIsFavorite(!isFavorite);
  };

  const hasMissingIngredients = userData && drink.missing_ingredients;
  const ingredientsNames = drink.ingredients.map((item) => item.ingredient);
  const existingIngredients = hasMissingIngredients
    ? ingredientsNames.filter((n) => !drink.missing_ingredients.includes(n))
    : [];

  return (
    <Box
      key={drink.name}
      bg={cocktailColor}
      boxShadow="sm"
      borderRadius="md"
      p={3}
      position="relative"
      textAlign="center"
    >
      <Grid height="35vh" templateRows="repeat(10, 1fr)" templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={4} rowSpan={8} overflowY="auto">
          <Flex direction="column">
            <Box paddingX="1.5rem">
              <Image
                src={drink.picture}
                alt={drink.name}
                boxSize="10rem"
                objectFit="cover"
                borderRadius="md"
                m="auto"
              />
              <Text fontSize="lg" mt={2}>
                {drink.name}
              </Text>
              <Flex align="center" justify="center" wrap="wrap" direction="row" mt={1} mb={1} minHeight="24px">
                {drink.labels && drink.labels.length > 0 ? (
                  drink.labels.map((label) => (
                    <Badge px={2} py={1} key={label} m="1" fontSize="10">
                      {label}
                    </Badge>
                  ))
                ) : (
                  <Box minHeight="24px"></Box> // Ensure consistent height
                )}
              </Flex>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={4} rowSpan={1}>
          <Tooltip
            label={
              <>
                <Box>
                  {existingIngredients.map((ingredient, index) => (
                    <Text key={`exist${index}`} color="green.500">
                      {ingredient}
                      <br />
                    </Text>
                  ))}
                </Box>
                <Box>
                  {drink?.missing_ingredients?.map((ingredient, index) => (
                    <Text key={`miss${index}`} color="red.500">
                      {ingredient}
                      <br />
                    </Text>
                  ))}
                </Box>
              </>
            }
            isDisabled={!isLoggedIn}
          >
            <Text fontSize="sm" color="gray.500">
              {drink.ingredients.length - (drink.missing_ingredients?.length ? drink.missing_ingredients.length : drink.ingredients.length)} / {drink.ingredients.length} Ingredients
            </Text>
          </Tooltip>
        </GridItem>
        <GridItem colSpan={4} rowSpan={1}>
          <Flex justify="space-between" width="100%">
            <Flex
              p={4}
              alignItems="center"
              justifyContent="space-between"
              roundedBottom="sm"
              cursor="pointer"
              w="full"
              borderTopWidth="1px"
              borderColor="#6b6b6b"
              borderRadius="0px"
            >
              <Text fontSize="md" fontWeight="semibold" as="u">
                <Link href={`/cocktail/${drink.name}`}>
                  <Flex align="center">
                  <MdMenuBook style={{ marginRight: '4px' }} /> To Recipe
                  </Flex>
                </Link>
              </Text>
            </Flex>
            {isLoggedIn ? (
              <Flex borderTopWidth="1px" borderColor="#6b6b6b" px="1rem" cursor="pointer" onClick={toggleFavorite}>
                <Center>
                  {isFavorite ? (
                    <FaHeartCircleXmark fill="red" fontSize="24px" />
                  ) : (
                    <FaHeartCirclePlus fontSize="24px" />
                  )}
                </Center>
              </Flex>
            ) : (
              <Flex borderTopWidth="1px" borderColor="#6b6b6b" px="1rem" cursor="not-allowed">
                <Center>
                  <FaHeart fill="grey" fontSize="24px" />
                </Center>
              </Flex>
            )}
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
}