import useApiClient from "services/useApiClient";

const useIngredientService = () => {
  const apiClient = useApiClient();

  const getIngredientsByNameFilter = async (name) => {
    return await apiClient(`/api/ingredient?name=${name}`);
  };

  const getIngredientsByIds = async (ids) => {
    const listIds = "?ids=" + ids.map((id) => id).join("&ids=");
    return await apiClient(`/api/ingredient${listIds}`);
  };

  const getCategorizedIngredients = async () => {
    return await apiClient("/api/ingredient/categorized");
  };

  const getIngredientByName = async (name) => {
    return await apiClient(`/api/ingredient/${name}`);
  }

  return {
    getIngredientsByNameFilter,
    getIngredientsByIds,
    getCategorizedIngredients,
    getIngredientByName,
  };
};

export default useIngredientService;
