import useApiClient from "services/useApiClient";

const useShelfService = () => {
  const apiClient = useApiClient();

  const updateShelf = async (listOfIngredients) => {
    return await apiClient('/api/shelf', {method: 'PUT', body: listOfIngredients});
  };

  return {
    updateShelf,
  };
};

export default useShelfService;
