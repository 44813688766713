import { useEffect, useState } from "react";

import { useAuth } from "AuthContext";
import { Navigate } from 'react-router-dom';

import LoadingSpinner from "components/Loading";
import useShelfService from "services/shelfService";
import IngredientService from "services/ingredientService";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Grid, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react';

import BasePage from "./basePage";

export default function ShelfPage() {
  const cocktailColor = useColorModeValue("gray.400", "gray.900")
  const { isLoggedIn, userData } = useAuth();
  const [ shelf, setShelf ] = useState(null);
  const { getIngredientsByIds, getCategorizedIngredients } = IngredientService();
  const { updateShelf } = useShelfService();

  const fetchShelfIngredients = async () => {
    if (userData.shelf !== undefined && userData.shelf !== null) {
      setShelf(await getIngredientsByIds(userData.shelf.ingredient_ids));
    } else if (userData.shelf !== null) {
      setShelf([]);
    }
  }

  useEffect(() => {
    fetchShelfIngredients();
  }, [userData]);

  if (!isLoggedIn) {
    return <Navigate to="/not-found" />
  }

  const SideBar = () => {
    const [ ingredients, setIngredients ] = useState({});

    const fetchIngredients = async () => {
      setIngredients(await getCategorizedIngredients());
    }

    useEffect(() => {
      fetchIngredients();
    }, [userData]);

    const handleShelfChanged = (key, value) => (e) => {
      if (e.target.checked) {
        const newShelf = [...shelf, value]
        setShelf(newShelf);
        const ids = JSON.stringify(newShelf.map((obj) => obj.id))
        updateShelf(ids);
      } else {
        const newShelf = shelf.filter(item => item.name !== value.name)
        setShelf(newShelf)
        const ids = JSON.stringify(newShelf.map((obj) => obj.id))
        updateShelf(ids);
      }
    };

    return (
      <>
        <Text fontSize="xl" mb={4} mt={4}>Manage Shelf</Text>
        <Accordion allowMultiple defaultIndex={[0]}>
          {Object.keys(ingredients).map((key) => (
            <AccordionItem key={key}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {key}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={2}>
                  {ingredients[key].map((value, index) => (
                    <Checkbox
                      name={value.name}
                      key={index}
                      value={value.name}
                      isChecked={shelf.map((obj) => obj.id).includes(value.id)}
                      onChange={handleShelfChanged(key, value)}
                    >
                      {value.name}
                    </Checkbox>
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </>
    )
  }

  return (
    <BasePage
      sidebar={
        <SideBar />
      }
      mainContent={
        <>
        <Text fontSize="lg" mb={5}>Shelf</Text>
        {shelf === null ? <LoadingSpinner /> : 
          shelf.length === 0 ? <Text>You dont have any ingredient in your shelf!</Text> :
          <Grid templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)', 'repeat(6, 1fr)']} gap={6}>
            {shelf.map((ingredient) => (
              <Box key={ingredient.name} bg={cocktailColor} boxShadow="sm" borderRadius="md" p={3} position="relative" textAlign="center">
                <Image
                  src={ingredient.picture}
                  alt={ingredient.name}
                  boxSize="100px"
                  objectFit="cover"
                  borderRadius="md"
                  m="auto" />
                <Text fontSize="lg" mt={3}>{ingredient.name}</Text>
              </Box>
            ))}
          </Grid>
        }
        </>
      }
    />
  )
}
