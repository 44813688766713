import React, { useEffect, useState } from "react";

import { useAuth } from "AuthContext";
import { Navigate } from 'react-router-dom';

import useDrinkService from "services/drinkService";
import FileUploadForm from "components/FileUpload.tsx"
import { AddIcon, DownloadIcon, InfoIcon, MinusIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, Divider, Flex, Grid, GridItem, Heading, HStack, IconButton, Image, Input, InputGroup, Stack, Table, TableContainer, Tag, Tbody, Td, Text, Textarea, Th, Thead, Tr, VStack } from "@chakra-ui/react";

const DrinkContext = React.createContext({
  drinks: [], fetchDrinks: () => {}
})

function AddDrink() {
  const [name, setName] = useState("");
  const [picture, setPicture] = useState("");
  const [ingredients, setIngredients] = useState([{ ingredient: "", amount: "" }]);
  const [steps, setSteps] = useState("");
  const [labels, setLabels] = useState("");
  const [authorNotes, setAuthorNotes] = useState("");

  const { fetchDrinks } = React.useContext(DrinkContext);

  const { token } = useAuth();

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handlePictureChange = (event) => {
    setPicture(event.target.value);
  }

  const handleIngredientsChange = (index, event) => {
    const { name, value } = event.target;
    const newIngredients = [...ingredients];
    newIngredients[index][name] = value;
    setIngredients(newIngredients);
  }

  const handleStepsChange = (event) => {
    setSteps(event.target.value);
  }

  const handleLabelsChange = (event) => {
    setLabels(event.target.value);
  }

  const handleAuthorNotesChange = (event) => {
    setAuthorNotes(event.target.value);
  }

  const handleAddPair = () => {
    setIngredients([...ingredients, { ingredient: "", amount: "" }]);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);
    setIngredients(newIngredients);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newDrink = {
      "name": name,
      "picture": picture,
      "ingredients": ingredients,
      "steps": steps,
      "labels": labels.split(",").map(function(label) {
        return label.trim();
      }),
      "author_notes": authorNotes
    }

    fetch("/api/drink", {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(newDrink)
    }).then((res) => {
      fetchDrinks();
      if(res.ok) {
        setName("");
        setPicture("");
        setIngredients([{ ingredient: "", amount: "" }]);
        setSteps("");
        setLabels("");
        setAuthorNotes("");
      }
    });
  }

  return (
    <>
      <Card maxW="sm" key="add-drink">
        <CardBody>
          <form onSubmit={handleSubmit}>
            <InputGroup size="md">
              <VStack mt="6" spacing="3">
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="Add a name for a drink"
                  aria-label="Add a name for a drink"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                />
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="Add a picture for a drink"
                  aria-label="Add a picture for a drink"
                  name="picture"
                  value={picture}
                  onChange={handlePictureChange}
                />
                {ingredients.map((pair, index) => (
                    <Stack key={index}>
                        <Flex>
                            <Input
                              mr={2}
                              type="text"
                              placeholder="Ingredient"
                              aria-label="Ingredient"
                              name="ingredient"
                              value={pair.ingredient}
                              onChange={(e) => handleIngredientsChange(index, e)}
                            />
                            <Input
                              mr={2}
                              type="text"
                              placeholder="Add amount"
                              aria-label="Add amount"
                              name="amount"
                              value={pair.amount}
                              onChange={(e) => handleIngredientsChange(index, e)}
                            />

                            {index === ingredients.length - 1 && (
                                <IconButton aria-label='Add Ingredient Pair' icon={<AddIcon />} onClick={handleAddPair} />
                            )}
                            {ingredients.length > 1 && (
                                <IconButton aria-label='Remove Ingredient Pair' icon={<MinusIcon />} onClick={() => handleRemoveIngredient(index)} />
                            )}
                        </Flex>
                    </Stack>
                ))}
                <Textarea
                  pr="4.5rem"
                  type="text"
                  placeholder="Add a steps for a drink"
                  aria-label="Add a steps for a drink"
                  name="steps"
                  value={steps}
                  onChange={handleStepsChange}
                />
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="Add a labels for a drink"
                  aria-label="Add a labels for a drink"
                  name="labels"
                  value={labels}
                  onChange={handleLabelsChange}
                />
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="Add a author_notes for a drink"
                  aria-label="Add a author_notes for a drink"
                  name="authorNotes"
                  value={authorNotes}
                  onChange={handleAuthorNotesChange}
                />
              </VStack>
            </InputGroup>
            <Divider />
            <CardFooter>
              <Button mt={4} colorScheme="teal" type="submit">
                Import
              </Button>
            </CardFooter>
          </form>
        </CardBody>
      </Card>
    </>
  )
}

export default function Drinks() {
  const [drinks, setDrinks] = useState([]);
  const { isLoggedIn, token } = useAuth();

  const { getAllDrinks } = useDrinkService();
  
  const fetchDrinks = async () => {
    setDrinks(await getAllDrinks())
  }
  const deleteDrink = (drinkName) => {
    fetch(`/api/drink/${drinkName}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    }).then(fetchDrinks)
  }
  useEffect(() => {
    fetchDrinks()
  }, [])

  if (!isLoggedIn) {
    return <Navigate to="/not-found" />
  }


  const exportDrinks = () => {
    fetch('/api/drink/export', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `export-drinks.json`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }
  return (
    <>
      <Grid templateColumns='repeat(5, 1fr)' gap={4}>
        <GridItem>
          <FileUploadForm />
        </GridItem>
        <GridItem>
        <Button leftIcon={<DownloadIcon />} onClick={() => {exportDrinks()}}>
          Export
        </Button>
        </GridItem>
      </Grid>
      <DrinkContext.Provider value={{drinks, fetchDrinks}}>
        <Flex wrap="wrap" gap="4" p="4">
          <AddDrink />
          {drinks.map((drink) => (
            <Box key={drink.name} minW="sm" p="4" maxW="sm">
              <Card minW="sm">
                <CardBody>
                  <Image
                    src={drink.picture}
                    alt={drink.name}
                    borderRadius="lg"
                  />
                  <Stack mt="6" spacing="3">
                    <Heading size="md">{drink.name}</Heading>
                    <Text>
                      <div dangerouslySetInnerHTML={{ __html: drink.steps }} />
                    </Text>
                    <TableContainer>
                      <Table size='sm'>
                        <Thead>
                          <Tr>
                            <Th>Ingredient</Th>
                            <Th>Amount</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {drink.ingredients.map((pair, index) => (
                            <Tr key={index}>
                              <Td>{pair.ingredient}</Td>
                              <Td>{pair.amount}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    {drink.author_notes && (
                    <Box bg='tomato' w='100%' p={2} color='white'>
                      <InfoIcon /> {drink.author_notes}
                    </Box>
                    )}
                    <>
                      <HStack spacing={4}>
                        {drink.labels.map((label) => (
                          <Tag spacing="3">{label}</Tag>
                        ))}
                      </HStack>
                    </>
                  </Stack>
                </CardBody>
                <Divider />
                <CardFooter>
                  <ButtonGroup spacing="2">
                    <Button variant="solid" colorScheme="blue" isDisabled>
                      Edit
                    </Button>
                    <Button variant="ghost" colorScheme="red" onClick={() => {deleteDrink(drink.name, token)}}>
                      Delete
                    </Button>
                  </ButtonGroup>
                </CardFooter>
              </Card>
            </Box>
          ))}
        </Flex>
      </DrinkContext.Provider>
    </>
  )
}