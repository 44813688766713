// src/utils/useGoogleAnalytics.js
import { useEffect } from "react";

export const useGoogleAnalytics = (measurementId) => {
  useEffect(() => {
    // Check if GA script is already added
    if (document.querySelector(`script[src*="${measurementId}"]`)) {
      console.log("Google Analytics script already added.");
      return;
    }

    // Create the script element
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.async = true;
    document.head.appendChild(script);
    console.log("Google Analytics script added.");

    // Initialize the data layer
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", measurementId);
    console.log("Google Analytics initialized.");

    // Clean up when component unmounts
    return () => {
      document.head.removeChild(script);
      console.log("Google Analytics script removed.");
    };
  }, [measurementId]);
};