import useApiClient from "services/useApiClient";

const useDrinkService = () => {
  const apiClient = useApiClient();

  const getDrink = async (drinkName) => {
    return await apiClient(`/api/drink/${drinkName}`);
  };

  const getAllDrinks = async () => {
    return await apiClient("/api/drink");
  };

  const getFeaturedDrinks = async () => {
    return await apiClient("/api/drink/featured");
  };

  const getRelatedDrinks = async () => {
    return await apiClient("/api/drink/featured");
  };

  const deleteDrink = (drinkName, token) => {
    fetch(`/api/drink/${drinkName}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getDrinksByNameFilter = async (name) => {
    const response = await fetch(`/api/drink?name=${name}`);
    const drinks = await response.json();
    return drinks;
  };

  const getDrinksByFilters = async (data) => {
    const keymap = {
      "Base Spirits": "ingredients",
      Tastes: "labels",
      "Cocktail Types": "labels",
      "Preparation Methods": "preparation_methods",
    };

    const queryParts = [];
    Object.keys(data).forEach((key) => {
      if (key in keymap) {
        data[key].forEach((value) => {
          queryParts.push(
            `${encodeURIComponent(keymap[key])}=${encodeURIComponent(value)}`
          );
        });
      }
    });

    if (data["Type Of Drinks"] && data["Type Of Drinks"].length > 0) {
      if (data["Type Of Drinks"][0] === "Shot") {
        queryParts.push(`is_cocktail=false`);
      } else {
        queryParts.push(`is_cocktail=true`);
      }
    }

    try {
      return await apiClient(`/api/drink?${queryParts.join("&")}`);
    } catch (error) {
      return [];
    }
    //return await apiClient(`/api/drink?${queryParts.join("&")}`);
  };

  return {
    getDrink,
    getAllDrinks,
    getFeaturedDrinks,
    getRelatedDrinks,
    deleteDrink,
    getDrinksByNameFilter,
    getDrinksByFilters
  };
};

export default useDrinkService;
