import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
	config: {
		initialColorMode: "system",
		useSystemColorMode: false
	},
	// https://v1.chakra-ui.com/docs/styled-system/features/global-styles
	styles: {
		global: (props) => ({
			body: {
				bg: mode("gray.200", "gray.700")(props),
			},
		})
	}
};
