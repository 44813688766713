import useApiClient from "services/useApiClient";

const useCommonService = () => {
  const apiClient = useApiClient();

  const getDrinksAndIngredientsByNameFilter = async (name) => {
    return await apiClient(`/api/common/filter-all?name=${name}`);
  };

  const getFilters = async () => {
    return await apiClient(`/api/common/filters`);
  };

  return {
    getDrinksAndIngredientsByNameFilter,
    getFilters,
  };
};

export default useCommonService;
