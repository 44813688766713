import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Container, Grid, GridItem, Image, Text } from "@chakra-ui/react";

import useIngredientService from "services/ingredientService";

import BasePage from "./basePage";

export default function IngredientPage() {
    let { ingredientName } = useParams();
    const [ ingredient, setIngredient ] = useState({});
    const { getIngredientByName } = useIngredientService();

    const fetchIngredient = async () => {
      const result = await getIngredientByName(ingredientName);
      setIngredient(result);
    }

    useEffect(() => {
      fetchIngredient();
    }, []);

    <BasePage
      sidebar={
        <Image src={`https://bit.ly/dan-abramov`} alt={ingredientName} boxSize="200px" />
      }
      mainContent={
        <>
          <Container>
              <header>ingredient name</header>
          </Container>
          <Container maxW={"90vw"} marginBlockStart={"20vh"}>
              <Grid
                  templateAreas={`
                              "image desc featured"
                              "labels desc featured"
                              "info info info"
                              `}
                  gridTemplateColumns={"2fr 4fr 1fr"}
                  gridTemplateRows={"250px 1fr 2fr"}
                  gap={3}>
                      <GridItem area={"image"}><Image src="https://bit.ly/dan-abramov" alt="Dan Abramov" boxSize="150px" /></GridItem>
                  <GridItem area={"desc"}><Text fontSize="xl"> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, cum. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio debitis, voluptas alias cupiditate repellendus ipsam! Maiores possimus a blanditiis voluptatem.</Text></GridItem>
                  <GridItem area={"featured"}><p>component</p></GridItem>
                  <GridItem area={"labels"}><p>object.labels Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem dolorem, ea facilis mollitia nobis molestias placeat aliquid eaque vel! Qui sequi sed rem impedit perspiciatis, explicabo natus dolores voluptates doloremque.</p></GridItem>
                  <GridItem area={"info"}><Text hidden={false}> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum, recusandae! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perspiciatis, repellendus aliquam odit porro iure facere, molestias suscipit, eveniet placeat cupiditate adipisci quidem tempora consequuntur eos atque eligendi. Quibusdam, voluptatum iure.</Text></GridItem>
                  {/* does it goes back to hidden if we click the same 1 again? or visible forever? */}
              </Grid>
          </Container>
        </>
      }
    />

}
