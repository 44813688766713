import { useEffect, useState } from "react";

import { Link as ReactLink } from "react-router-dom";

import { FaFilter } from "react-icons/fa";
import { SearchIcon } from "@chakra-ui/icons";
import LoadingSpinner from "components/Loading";
import useDrinkService from "services/drinkService";
import useCommonService from "services/commonService";
import useIngredientService from "services/ingredientService";
import { Box, Flex, IconButton, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Text } from '@chakra-ui/react'

const SearchBar = () => {
  const [searchType, setSearchType] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [results, setResults] = useState([]);
  const [isFocused, setIsFocused] = useState('hidden');

  const { getDrinksAndIngredientsByNameFilter } = useCommonService();
  const { getDrinksByNameFilter } = useDrinkService();
  const { getIngredientsByNameFilter } = useIngredientService();

  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  const handleInputChange = (event) => {
    setDebouncedInputValue(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchValue(debouncedInputValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [debouncedInputValue]);

  useEffect(() => {
    setIsloading(true);
    if (searchValue.length > 0) {
      switch (searchType) {
        case 'all':
          const fetchAllData = async () => {
            const response = await getDrinksAndIngredientsByNameFilter(searchValue);
            setResults(response);
          }
          fetchAllData();
          break;

        case 'drinks':
          const fetchDrinksData = async () => {
            const response = await getDrinksByNameFilter(searchValue);
            setResults(response);
          }
          fetchDrinksData();
          break;
        
        case 'ingredients':
          const fetchIngredientsData = async () => {
            const response = await getIngredientsByNameFilter(searchValue);
            setResults(response);
          }
          fetchIngredientsData();
          break;

        // no default
      }
    } else {
      setResults([]);
    }
    setIsloading(false);
  }, [searchType, searchValue]);

  return (
    <Box position="relative" width="100%">
      <InputGroup size='md' zIndex={200}>
        <InputLeftElement pointerEvents='none'>
          {isLoading ? <LoadingSpinner /> : <SearchIcon color='gray.300' />}
        </InputLeftElement>
        <Input
          name="search"
          pr='4.5rem'
          type='text'
          placeholder='Search...'
          onChange={handleInputChange}
          onFocus={() => setIsFocused('visible')}
          onBlur={() => setIsFocused('hidden')}
        />
        <Menu>
          <>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaFilter />}
              variant='outline'
            />
            <MenuList>
              <MenuOptionGroup defaultValue='all' title='Search Category' type='radio'>
                <MenuItemOption onClick={() => setSearchType('all')} value='all'>All</MenuItemOption>
                <MenuItemOption onClick={() => setSearchType('drinks')} value='drinks'>Cocktails</MenuItemOption>
                <MenuItemOption onClick={() => setSearchType('ingredients')} value='ingredients'>Ingredients</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </>
        </Menu>
      </InputGroup>
      <Box
        overflowY='auto'
        borderRadius='0.3rem'
        zIndex={100}
        position='absolute'
        visibility={isFocused}
        width="100%"
        bg="white"
        mt="2"
      >
        {results.length > 0
          ? results.map(drink => (
            <ReactLink to={`cocktails/${drink.name}`} key={drink.name}>
              <Box
                cursor='pointer'
                backgroundColor='black'
                color='white'
                width='100%'
                maxWidth='100%'
                p="2"
                borderBottom="1px solid #eee"
              >
                <Flex alignItems='center'>
                  {drink.name}
                </Flex>
              </Box>
            </ReactLink>
          ))
          : searchValue.length > 0 && !isLoading && (
            <Box
              borderBottom="1px solid rgba(34,36,38,.1)"
              p="0.8em"
              color="black"
            >
              <Flex alignItems="center">
                <Text>No results found</Text>
              </Flex>
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

export default SearchBar;