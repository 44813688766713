import { useAuth } from "AuthContext";

import Nav from "components/Navbar";
import useCheckMobileScreen from "services/useCheckMobileScreen";
import { Box, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';

export default function BasePage({ sidebar, mainContent }) {
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const bgContainerColor = useColorModeValue("gray.300", "gray.800");
  const textColor = useColorModeValue("black", "blue.100");
  const isMobile = useCheckMobileScreen();

  const { isLoggedIn } = useAuth();

  return (
    <Box h="100vh" display="flex" justifyContent="center" alignItems="flex-start" bg={bgColor} p={4}>
      <Box maxW="1200px" w="100%" bg={bgContainerColor} boxShadow="lg" borderRadius="md" overflow="hidden" {...(!isMobile ? {maxH: "100%"} : {})}>
        <Flex direction={['column', 'row']} w="100%">
          <Box bg={bgContainerColor} color={textColor} w={['100%', '250px']} p={5}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
              <Link href="/"><Text fontSize="2xl" fontWeight="bold">Logo</Text></Link>
            </Flex>
            {sidebar}
          </Box>
          <Box flex="1" p={5}>
            <Nav loggedIn={isLoggedIn} />
            <Box overflowY={'auto'} maxH={'90vh'}>
              {mainContent}
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
