import { useAuth } from 'AuthContext';

const useApiClient = () => {
  const auth = useAuth();

  const apiClient = async (endpoint, options = {}) => {
    const token = auth?.token;
    const headers = new Headers(options.headers || {'Content-Type': 'application/json'});

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(endpoint, {
      ...options,
      headers,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  };

  return apiClient;
};

export default useApiClient;
