import React from "react";

import { useAuth } from "AuthContext";

import { BsMoonStarsFill, BsSun } from 'react-icons/bs';
import { Box, Button, Flex, Stack, useColorMode, useColorModeValue } from '@chakra-ui/react';

import Shelf from "./Navbar/Shelf";
import SearchBar from './SearchBar';
import UserDroplist from "./Navbar/UserDroplist";
import LoginButtonDrawer from "./login/LoginButtonDrawer";

export default function Nav() {
  const { isLoggedIn } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <SearchBar />

        <Flex alignItems={'center'}>
          <Stack direction={'row'} spacing={2}>
            <Shelf />
            {isLoggedIn ? (
              <>
                <Button
                  aria-label="Toggle Color Mode"
                  onClick={toggleColorMode}
                  _focus={{ boxShadow: 'none' }}
                  w="fit-content">
                  {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
                </Button>
                <UserDroplist />
              </>
            ) : (
              <LoginButtonDrawer />
            )}
            
          </Stack>
        </Flex>
      </Flex>
    </Box>
  )
}
