import { useAuth } from "AuthContext";

import { Box, Tooltip, useColorModeValue } from "@chakra-ui/react";

export default function Shelf() {
  const { isLoggedIn } = useAuth();

  const boxBg = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.300', 'gray.600');
  const tooltipLabel = "Only registered users can use Shelf";

  return(
    <Tooltip label={tooltipLabel} isDisabled={isLoggedIn}>
      <Box
        as={isLoggedIn ? 'a' : 'div'}
        href={isLoggedIn ? "/shelf" : undefined}
        cursor={isLoggedIn ? 'pointer' : 'not-allowed'}
        alignContent="center"
        bg={boxBg}
        _hover={{
          bg: isLoggedIn ? hoverBg : boxBg,
        }}
        p={2}
        borderRadius="md"
        ml={2}
      >
        Shelf
      </Box>
    </Tooltip>
  )
}
