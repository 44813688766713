import { useEffect, useState } from "react";

import { useAuth } from "AuthContext";
import { useParams } from "react-router-dom";

import { InfoIcon } from "@chakra-ui/icons"
import useUserService from "services/userService";
import useDrinkService from "services/drinkService";
import { FaHeartCirclePlus, FaHeartCircleXmark } from "react-icons/fa6";
import { Box, GridItem, HStack, Image, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Table, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import BasePage from "./basePage";

export default function Home() {
  let { cocktailName } = useParams();
  const [drink, setDrink] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [persons, setPersons] = useState(1);
  const { getDrink } = useDrinkService();
  const { checkFavorite, deleteFavorite, addFavorite } = useUserService();
  const [ existingIngredients, setExistingIngredients ] = useState([]);
  const { userData } = useAuth();
  const [ missingIngredients, setMissingIngredients ] = useState([]);
  const ozToMl = 29.57;
  const mlToOz = 0.033814;

  const handleChangePersons = (newPersons) => {
    setPersons(newPersons);
  }

  const fetchDrink = async () => {
    try {
      const result = await getDrink(cocktailName);
      setDrink(result);
    
      const hasMissingIngredients = userData && result.missing_ingredients;
      const ingredientsNames = result.ingredients.map(item => item.ingredient);
      const uniqueExistingIngredients = hasMissingIngredients ? ingredientsNames.filter(n => !result.missing_ingredients.includes(n)) : [];
      setExistingIngredients(uniqueExistingIngredients);

      const uniqueMissingIngredients = hasMissingIngredients ? result.missing_ingredients.filter(ingredient => !uniqueExistingIngredients.includes(ingredient)) : [];
      setMissingIngredients(uniqueMissingIngredients);

      setIsFavorite(await checkFavorite(result.id));
    } catch (error) {
      console.error("Failed to fetch drink:", error);
    }
  }

  const toggleFavorite = async () => {
    if (isFavorite) {
      await deleteFavorite(drink.id);
    } else {
      await addFavorite(drink.id);
    }
    setIsFavorite(!isFavorite);
  }

  useEffect(() => {
    fetchDrink();
  }, [userData]);

  useEffect(() => {
    if (!drink.ingredients) return;

    const updatedDrink = { ...drink, ingredients: drink.ingredients?.map(ingredient => {
      if (userData && userData.measure_units === "ml" && ingredient.amount_type === "oz") {
        return { ...ingredient, amount: ingredient.amount * ozToMl, amount_type: "ml" };
      } else if (userData && userData.measure_units !== "ml" && ingredient.amount_type === "ml") {
        return { ...ingredient, amount: ingredient.amount * mlToOz, amount_type: "oz" };
      } else if (!userData && ingredient.amount_type === "ml") {
        return { ...ingredient, amount: ingredient.amount * mlToOz, amount_type: "oz" };
      }

      return ingredient;
    })};
    setDrink(updatedDrink);
  }, [userData, drink.ingredients]);

  return (
    <BasePage
      sidebar={
        <>
          <Text fontSize="xl" mb={4} mt={4}>{drink.name}{isFavorite ? <FaHeartCircleXmark onClick={toggleFavorite} fill="red" /> : <FaHeartCirclePlus onClick={toggleFavorite} />}</Text>
          <Image src={`/${drink.picture}`} alt={drink.name} boxSize="200px" />
          <HStack spacing={8}>
            {drink.labels?.map((label) => (
              <Tag spacing="3" key={label}>{label}</Tag>
            ))}
          </HStack>
          <GridItem colSpan={4} rowSpan={1}>
            <Box>
              {existingIngredients.map((ingredient, index) => (
                <Text key={`exist${index}`} color="green.500">
                  {ingredient}
                  <br />
                </Text>
              ))}
            </Box>
            <Box>
              {missingIngredients.map((ingredient, index) => (
                <Text key={`miss${index}`}>
                  {ingredient}
                  <br />
                </Text>
              ))}
            </Box>
          </GridItem>
        </>
      }
      mainContent={
        <>
          <Text>{drink.steps}</Text>
          <HStack spacing={2}>
            <Text>Persons:</Text>
            <NumberInput size='sm' maxW={20} defaultValue={persons} min={1} onChange={(_, valueAsNumber) => handleChangePersons(valueAsNumber)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
          <TableContainer>
            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th>Ingredient</Th>
                  <Th>Amount</Th>
                </Tr>
              </Thead>
              <Tbody>
                {drink.ingredients?.map((pair, index) => (
                  <Tr key={index}>
                    <Td>{pair.ingredient}</Td>
                    <Td>{pair.amount * persons} {pair.amount_type}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {drink.author_notes && (
          <Box bg='tomato' w='100%' p={2} color='white'>
            <InfoIcon /> {drink.author_notes}
          </Box>
          )}
        </>
      }
    />
  )
}
