import useApiClient from "services/useApiClient";

const useUserService = () => {
  const apiClient = useApiClient();

  const getMyUserData = async (token) => {
    return await apiClient('/api/user/me', {'headers': {'Authorization': `Bearer ${token}`}});
  };

  const addFavorite = async (drink_id) => {
    return await apiClient(`/api/user/favorite?drink_id=${drink_id}`, {'method': 'POST'})
  };
  
  const deleteFavorite = async (drink_id) => {
    return await apiClient(`/api/user/favorite?drink_id=${drink_id}`, {'method': 'DELETE'})
  };

  const updateSettings = async (form) => {
    return await apiClient('/api/user/data', {'method': 'PUT', body: form})
  }

  const checkFavorite = async (drink_id) => {
    try {
      await apiClient(`/api/user/favorite?drink_id=${drink_id}`, {'method': 'GET'});
      return true;
    } catch (error) {
      return false;
    }
  };

  const getFavorites = async () => {
    return await apiClient('/api/user/favorites', {'method': 'GET'});
  }

  return {
    getMyUserData,
    addFavorite,
    deleteFavorite,
    updateSettings,
    checkFavorite,
    getFavorites,
  };
};

export default useUserService;
