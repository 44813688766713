import React from "react";

import axios from "axios";
import { useAuth } from "AuthContext";

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { AbsoluteCenter, Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Stack, useDisclosure, useToast } from '@chakra-ui/react';

export default function LoginButtonDrawer(){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [showPassword, setShowPassword] = React.useState(false);

  const [loginEmail, setLoginEmail] = React.useState("");
  const [loginPassword, setLoginPassword] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [username, setUsername] = React.useState("");

  const toast = useToast();

  const { login } = useAuth();

  const handleLogin = async () => {
    if (loginEmail === "" | loginPassword === "") {
      toast({
        title: "Error",
        description: "email / password is empty",
        status: "error",
        isClosable: true,
      });
    } else {
      const response = await axios.post("/api/user/login", new URLSearchParams({
        username: loginEmail,
        password: loginPassword,
      })).catch((error) => {
        toast({
          title: "Error",
          description: error.response.data.detail,
          status: "error",
          isClosable: true,
        });
      });

      if (response?.data?.access_token) {
        toast({
          title: "Logged in!",
          status: "success",
          isClosable: true,
        });
        onClose();
        login(response.data.access_token);
      }
    }
  }

  const handleRegister = async () => {
    if (email === "" | password === "" | confirmPassword === "") {
      toast({
        title: "Error",
        description: "email / password / confirm password is empty",
        status: "error",
        isClosable: true,
      });
    } else if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "The passwords is different",
        status: "error",
        isClosable: true,
      });
    } else {
      const response = await axios.post("/api/user/signup", {
        username: username,
        email: email,
        password: password
      }).catch((error) => {
        toast({
          title: "Error",
          description: 'Something went wrong... Check your registration data',
          status: "error",
          isClosable: true,
        });
      });

      if (response?.data?.password) {
        toast({
          title: "Registration finish successfully, Now you can login",
          status: "success",
          isClosable: true,
        });
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setUsername('');
      }
    }
  }

  return(
    <Stack
      flex={{ base: 1, md: 0 }}
      justify={'flex-end'}
      direction={'row'}
      spacing={6}>
      <Button
        fontSize={'sm'}
        fontWeight={600}
        color={'white'}
        bg={'teal'}
        _hover={{
          bg: 'teal.300',
        }}
        onClick={onOpen}
        >
        Login
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        initialFocusRef={firstField}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Login
          </DrawerHeader>

          <DrawerBody>
            <form
              id='login-form'
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Stack spacing='24px'>
                <Box>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                  <Input
                    ref={firstField}
                    id='email'
                    type='email'
                    placeholder='Enter email'
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                  />
                </Box>

                <Box>
                  <FormLabel htmlFor='password'>Password</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Enter password'
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Icon as={ViewOffIcon} /> : <Icon as={ViewIcon} />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>

                <Box>
                  <Button colorScheme='blue' type='submit' form='login-form'>Login</Button>
                </Box>
              </Stack>
            </form>

            <Box position='relative' padding='10'>
              <Divider />
              <AbsoluteCenter px='4'>
                Register
              </AbsoluteCenter>
            </Box>
            <form
              id='register-form'
              onSubmit={(e) => {
                e.preventDefault();
                handleRegister();
              }}
            >
              <FormControl>
                <Stack spacing='24px'>
                  <Box>
                    <FormLabel htmlFor='username'>Username</FormLabel>
                    <Input
                      id='username'
                      type='text'
                      placeholder='Enter username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Box>
                  
                  <Box>
                    <FormLabel htmlFor='email-register'>Email</FormLabel>
                    <Input
                      id='email-register'
                      type='email'
                      placeholder='Enter email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>

                  <Box>
                    <FormLabel htmlFor='password-register'>Password</FormLabel>
                    <InputGroup size='md'>
                      <Input
                        id='password-register'
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Enter password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Icon as={ViewOffIcon} /> : <Icon as={ViewIcon} />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Box>

                  <Box>
                    <FormLabel htmlFor='password-confirm'>Confirm Password</FormLabel>
                    <InputGroup size='md'>
                      <Input
                        id='password-confirm'
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Confirm password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Icon as={ViewOffIcon} /> : <Icon as={ViewIcon} />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Box>

                  <Box>
                    <Button colorScheme='blue' type='submit' form='register-form'>Create Account</Button>
                  </Box>
                </Stack>
              </FormControl>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  )
}
