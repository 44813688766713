import { useNavigate } from 'react-router-dom';

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

import BasePage from "./basePage";

const ErrorPage = (props) => {
  const navigate = useNavigate()

  setTimeout(() => {
    navigate('/')
  }, 3000);

  return (
    <BasePage
      mainContent={
        <>
          <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={'red.500'}
                rounded={'50px'}
                w={'55px'}
                h={'55px'}
                textAlign="center">
                <CloseIcon boxSize={'20px'} color={'white'} />
              </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
              {props.statusCode ? `An error ${props.statusCode} occurred` : "An error occurred"}
            </Heading>
            <Text color={'gray.500'}>
              We apologize for the inconvenience. Please try again later.
            </Text>
            <Text fontSize="sm" color={'gray.500'}>
              You will be redirected to Home page in 3 seconds...
            </Text>
          </Box>
        </>
      }
    />
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;
