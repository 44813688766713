import { useEffect, useState } from "react";

import { useAuth } from "AuthContext";
import { Navigate } from "react-router-dom";

import useUserService from 'services/userService';
import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup, InputRightElement, Select, Stack, useToast } from "@chakra-ui/react";

import BasePage from "./basePage";

export default function SettingsPage() {
  const { userData, isLoggedIn } = useAuth();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [inputs, setInputs] = useState({
    username: userData?.username || "",
    email: userData?.email || "",
    password: "",
    confirmPassword: "",
    measure_units: userData?.measure_units || "oz",
    colors_mode: userData?.colors_mode || "dark",
  });
  const toast = useToast();

  const { updateSettings } = useUserService();

  useEffect(() => {
    resetForm();
  }, [userData]);

  if (!isLoggedIn) {
    return <Navigate to="/not-found" />;
  }

  const displayPassword = () => setShowPass(!showPass);
  const displayConfirmPassword = () => setShowConfirmPass(!showConfirmPass);
  const resetForm = () => {
    setInputs({
      username: userData?.username || "",
      email: userData?.email || "",
      password: "",
      confirmPassword: "",
      measure_units: userData?.measure_units || "oz",
      colors_mode: userData?.colors_mode || "dark",
    });
  };

  var errorMsg = "";

  const saveChanges = () => {
    if (inputs.password !== "") {
      if (inputs.password !== inputs.confirmPassword) {
        errorMsg = "The passwords is different";

        toast({
          title: "Error",
          description: errorMsg,
          status: "error",
          isClosable: true,
        });
        return errorMsg;
      }
    }
    updateSettings(JSON.stringify(inputs)).then(() => {
      toast({
        title: "Settings saved!",
        status: "success",
        isClosable: true,
      });
    }).catch((e) => {
      toast({
        title: "Error",
        description: "There was an error with updating.",
        status: "error",
        isClosable: true,
      });
    });
  };

  const isError = errorMsg !== "";

  return (
    <BasePage
      mainContent={
        <>
          <FormControl isInvalid={isError} id="username">
            <FormLabel>Username</FormLabel>
            <Input id="username" type="test" value={inputs.username} name="username" onChange={(ev) => setInputs({ ...inputs, username: ev.target.value })} autoComplete="username" />
            <FormHelperText>Your display (and login) name</FormHelperText>
            <FormErrorMessage>{errorMsg}</FormErrorMessage>
            <br/>
          </FormControl>

          <FormControl isInvalid={isError} id="email">
            <FormLabel>Email</FormLabel>
            <Input id="email" type="email" value={inputs.email}  name="email" onChange={(ev) => setInputs({ ...inputs, email: ev.target.value })} autoComplete="email" />
            <FormHelperText>We"ll never share your email.</FormHelperText>
            <br/>
          </FormControl>

          <FormControl isInvalid={isError} id="password">
          <FormLabel>Password</FormLabel>
            <InputGroup size="md">
              <Input
                id="password"
                pr="4.5rem"
                value={inputs.password}
                type={showPass ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                onChange={(ev) => setInputs({ ...inputs, password: ev.target.value })}
                autoComplete="new-password"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={displayPassword}>
                  {showPass ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <br/>
          </FormControl>

          <FormControl isInvalid={isError} id="confirmPassword">
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup size="md">
              <Input
                id="confirmPassword"
                pr="4.5rem"
                value={inputs.confirmPassword}
                type={showConfirmPass ? "text" : "password"}
                placeholder="Enter password"
                name="confirmPassword"
                onChange={(ev) => setInputs({ ...inputs, confirmPassword: ev.target.value })}
                autoComplete="new-password"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={displayConfirmPassword}>
                  {showConfirmPass ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <br/>
          </FormControl>

          <FormControl id="measure_units">
            <FormLabel>Measure Units</FormLabel>
            <Select value={inputs.measure_units} onChange={(ev) => setInputs({ ...inputs, measure_units: ev.target.value })}>
              <option value="oz">oz</option>
              <option value="ml">ml</option>
            </Select>
            <br/>
          </FormControl>

          <FormControl id="colors_mode">
            <FormLabel>Colors schema</FormLabel>
            <Select value={inputs.colors_mode} onChange={(ev) => setInputs({ ...inputs, colors_mode: ev.target.value })}>
              <option value="dark" >dark</option>
              <option value="light">light</option>
            </Select>
            <br/>
          </FormControl>

          <Stack spacing={6} direction={['column', 'row']}>
            <Button
              bg={'gray.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'gray.500',
              }}
              onClick={resetForm}
              >
              Reset
            </Button>
            <Button
              bg={'green.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'green.500',
              }}
              onClick={saveChanges}
              >
              Submit
            </Button>
          </Stack>
        </>
      }
    />
  );
}
